/* eslint-disable */

import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { font, color, ease, device } from "../../layout/global"
import { useScrollPosition } from "../../hooks/useScrollPosition"
import Headroom from "react-headroom"
import LangDropdown from "../LangDropdown"
import { useWindowSize } from '@react-hook/window-size/throttled'

import Logo from "../../images/Header/small-logo.svg"

// ---

// COMPONENT
const Header = ({ siteTitle, location }) => {
  const [isDarkened, setIsDarkened] = useState(false)
  const [bottomIsDarkened, setBottomIsDarkened] = useState(false)
  const [width, height] = useWindowSize()

  //CHANGE HEADER COLOR
  let colorDiv = '#introduction'
  let secondColorDiv = '#form-contact'

  const colorPositionStart = useRef(null), 
        colorPositionEnd = useRef(null), 
        secondColorPositionStart = useRef(null), 
        secondColorPositionEnd = useRef(null),
        thirdColorPositionStart = useRef(null), 
        thirdColorPositionEnd = useRef(null),
        introExists = useRef(null), 
        formExists = useRef(null),
        questionExists = useRef(null),
        headerWrapperRef = useRef(null),
        wrapperRef = useRef(null),
        introStart = useRef(null),
        introEnd = useRef(null),
        formStart = useRef(null),
        formEnd = useRef(null),
        questionStart = useRef(null),
        questionEnd = useRef(null),
        svgBig = useRef(null),
        svgSmall = useRef(null),
        timeout = useRef(null)

  const toScroll = () => {
    let element = document.querySelector(`#form-contact`)
    let offset = -1
    let bodyRect = document.body.getBoundingClientRect().top
    let elementRect = element.getBoundingClientRect().top
    let elementPosition = elementRect - bodyRect
    let offsetPosition = elementPosition - offset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    let colorDivX = document.querySelector(colorDiv)
    let secondColorDivX = document.querySelector(secondColorDiv)
    let thirdColorDivX = document.querySelector('#feature-1')
    let thirdColorDivXX = document.querySelector('#question')

    colorDivX && (colorPositionStart.current = colorDivX.offsetTop)
    colorDivX && (colorPositionEnd.current = colorDivX.offsetHeight)
    secondColorDivX && (secondColorPositionStart.current = secondColorDivX.offsetTop)
    secondColorDivX && (secondColorPositionEnd.current = secondColorDivX.offsetHeight)
    thirdColorDivX && (thirdColorPositionStart.current = thirdColorDivX.offsetTop)
    thirdColorDivX && (thirdColorPositionEnd.current = thirdColorDivXX.offsetHeight)

    colorDivX ? (introExists.current = true) : (introExists.current = false)
    secondColorDivX ? (formExists.current = true) : (formExists.current = false)
    thirdColorDivX ? (questionExists.current = true) : (questionExists.current = false)

    introStart.current = colorPositionStart.current;
    formStart.current = secondColorPositionStart.current;
    questionStart.current = thirdColorPositionStart.current;
    introEnd.current = (parseInt(colorPositionStart.current) + parseInt(colorPositionEnd.current));
    formEnd.current = (parseInt(secondColorPositionStart.current) + parseInt(secondColorPositionEnd.current));
    questionEnd.current = (parseInt(thirdColorPositionStart.current) + parseInt(thirdColorPositionEnd.current));
    
  }, [width, height, colorDiv, secondColorDiv])


  //GET SCROLL POSITION AND CHANGE COLOR
  useScrollPosition(
    ({ currPos }) => {
      let position = Math.abs(currPos.y);
      
      ((position > introStart.current && position <= introEnd.current) || (position > formStart.current && position <= formEnd.current) || (position > questionStart.current && position <= questionEnd.current)) ? setIsDarkened(true) : setIsDarkened(false);
    },
    [isDarkened]
  )

  return (
    <Wrapper
      isDarkened={isDarkened}
      bottomIsDarkened={bottomIsDarkened}
      location={location.pathname}
      id="header"
      key={window.location.pathname}
    >
      <Headroom
        disableInlineStyles
        style={{
          WebkitTransition: "all 1s ease-in-out",
          NozTransition: "all 1s ease-in-out",
          OTransition: "all 1s ease-in-out",
          transition: "all 1s ease-in-out",
        }}
        onUnpin={() => {
          document.querySelector("header").style.pointerEvents = "none"
        }}
        onPin={() => {
          document.querySelector("header").style.pointerEvents = "all"
        }}
      >
        <div className="header-wrapper">
          <div className="logo-container">
            <Link to="/en">
              <Logo />
            </Link>
          </div>
          <nav className="nav-primary">
            <nav className="nav-secondary">
              <LangDropdown lang="EN" location={location} />
            </nav>
          </nav>
        </div>
      </Headroom>

      <div className="bottom-wrapper">
        <nav className="nav-bottom">
          <button className='nav-link' href='#form-contact' onClick={toScroll}>Contact us</button>
        </nav>
      </div>
    </Wrapper>
  )
}

// PROP-TYPES
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

// STYLED
const Wrapper = styled.header`
  width: 100%;
  height: 75px;
  z-index: 2;
  position: fixed;
  opacity: 1;
  transition: all 0.3s ${ease.out};

  .header-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    ${font.robotoMedium};
    margin: 0 20px;
    height: 75px;
  }

  .logo-container {
    width: 200px;
    svg {
      transition: all ${ease.out} 0.3s !important;
      fill: ${props =>
        props.sidebarLinksColor ? color.white : 
        props.isDarkened ? color.greyDark : color.white
      };
    }
    z-index: 3;
  }

  .nav-primary {
    display: flex;
    flex-direction: row;
    margin: 0 0 0 auto;
  }

  .dropbtn {
    color: ${props =>
      props.sidebarLinksColor ? color.white : 
      props.isDarkened ? color.greyDark : color.white
    };

    svg{
      fill: ${props =>
        props.sidebarLinksColor ? color.white : 
        props.isDarkened ? color.greyDark : color.white
      };
    }
  }

  .nav-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom:10px;
    background:unset;
    border:none;
    outline:none;
    z-index:1;

    .nav-link {
      text-align: center;
      font-size: 1.2rem;
      color: #a6a6a6;
      text-transform: uppercase;
      text-decoration: none;
      margin-bottom:3px;
      border: none;
      background: none;
      outline: none;
    }

    &:before{
      content:'';
      width:10%;
      height:100%;
      position:absolute;
      top:0;
      border-bottom:2px solid #a6a6a6;
    }
  }

  .bottom-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    ${font.robotoMedium};
  }

  .nav-secondary {
    display: flex;
    flex-direction: row;
  }

  .opacity {
    opacity: 0 !important;
  }

  .display-none {
    display: none !important;
  }

  .nav-link {
    z-index: 3;
  }

  .nav-link,
  .nav-secondary {
    display: flex;
    align-items: center;
    color: ${props =>
      props.sidebarLinksColor
        ? color.whiteOpacity
        : props.location === "/assistencia"
        ? color.whiteOpacity
        : props.isDarkened && props.location !== "/assistencia"
        ? color.greyDark
        : color.white};
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: color ${ease.out} 0.3s;
    opacity: ${props =>
      !props.isDarkened && props.location === "/assistencia" ? 0 : 1};
    pointer-events: all;
    opacity: 100%;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.2s;

    &:hover {
      color: ${props => props.sidebarLinksColor && color.white};
    }
  }

  .hvr-overline-from-center.active > div {
    position: relative;

    &:after {
      content: "";
      height: 2px;
      width: 50px;
      background: ${color.red};
      display: block;
      margin: 0 auto;
    }
  }
`
